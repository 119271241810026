import { useTranslation } from 'react-i18next';
import { MarketDetails } from '../../types';
import { Typography } from '@/components';
import { MarketListItems } from './market-list-items';

export function MarketsDetails({ markets }: { markets: MarketDetails[] }) {
  const { t } = useTranslation(['insights']);
  return (
    <div className="space-y-3">
      <Typography as="h1" size="heading-1" color="primary" weight="semibold">
        {t('Market Prices')}
      </Typography>
      <Typography as="p" size="body-2" color="secondary" weight="normal">
        {t('Copy description market prices')}
      </Typography>

      <div>
        <MarketListItems markets={markets} />
      </div>
    </div>
  );
}
