import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { Field, Form, Input, Select, TextArea, Typography } from '@/components';
import { usePermitStatus } from '@/features/projects/hooks';
import { Permit, PermitStatus, ProjectTechnology } from '@/features/projects/types';
import { getDateFromQuarterAndYear, getQuarterFromDate, getYearFromDate, nullableStringSetter } from '@/helpers';
import { isBlank } from '@/types';

export type PermitsFormFields = {
  ivaId: string | null;
  ivaStatus: string | null;
  ivaQuarter: string | null;
  ivaYear: number | null;
  diaId: string | null;
  diaStatus: string | null;
  diaQuarter: string | null;
  diaYear: number | null;
  aapId: string | null;
  aapStatus: string | null;
  aapQuarter: string | null;
  aapYear: number | null;
  aacId: string | null;
  aacStatus: string | null;
  aacQuarter: string | null;
  aacYear: number | null;
  otherId: string | null;
  otherTitle: string | null;
  otherStatus: string | null;
  otherQuarter: string | null;
  otherYear: number | null;
  permiting_comments: string | null;
};

export type PermitsFormProps = {
  readonly id: string;
  readonly technology: ProjectTechnology;
  readonly onSubmit: (data: { permits: Permit[]; permiting_comments?: string | null }) => void;
};

function normalizeData(fields: PermitsFormFields): Permit[] {
  const permits: Permit[] = [];

  if (fields.ivaStatus) {
    permits.push({
      id: isBlank(fields.ivaId) ? undefined : fields.ivaId,
      type: 'connection',
      title: null,
      status: fields.ivaStatus as PermitStatus,
      date: fields.ivaQuarter ? getDateFromQuarterAndYear(fields.ivaQuarter, fields.ivaYear) : null,
    });
  }

  if (fields.diaStatus) {
    permits.push({
      id: isBlank(fields.diaId) ? undefined : fields.diaId,
      type: 'environmental',
      title: null,
      status: fields.diaStatus as PermitStatus,
      date: fields.diaQuarter ? getDateFromQuarterAndYear(fields.diaQuarter, fields.diaYear) : null,
    });
  }

  if (fields.aapStatus) {
    permits.push({
      id: isBlank(fields.aapId) ? undefined : fields.aapId,
      type: 'previous',
      title: null,
      status: fields.aapStatus as PermitStatus,
      date: fields.aapQuarter ? getDateFromQuarterAndYear(fields.aapQuarter, fields.aapYear) : null,
    });
  }

  if (fields.aacStatus) {
    permits.push({
      id: isBlank(fields.aacId) ? undefined : fields.aacId,
      type: 'construction',
      title: null,
      status: fields.aacStatus as PermitStatus,
      date: fields.aacQuarter ? getDateFromQuarterAndYear(fields.aacQuarter, fields.aacYear) : null,
    });
  }

  if (fields.otherStatus && fields.otherTitle) {
    permits.push({
      id: isBlank(fields.otherId) ? undefined : fields.otherId,
      type: 'other',
      title: fields.otherTitle,
      status: fields.otherStatus as PermitStatus,
      date: fields.otherQuarter ? getDateFromQuarterAndYear(fields.otherQuarter, fields.otherYear) : null,
    });
  }

  return permits;
}

export function PermitsForm({ id, technology, onSubmit }: PermitsFormProps) {
  const { t } = useTranslation(['projects']);

  const { options: statusOptions } = usePermitStatus();
  const quarterOptions = [
    { label: 'Q1', value: '1' },
    { label: 'Q2', value: '2' },
    { label: 'Q3', value: '3' },
    { label: 'Q4', value: '4' },
  ];

  const options = useMemo(
    () => ({
      defaultValues: {
        ivaId: technology.permits?.find((permit) => permit.type === 'connection')?.id,
        ivaStatus: technology.permits?.find((permit) => permit.type === 'connection')?.status,
        ivaQuarter: getQuarterFromDate(technology.permits?.find((permit) => permit.type === 'connection')?.date),
        ivaYear: getYearFromDate(technology.permits?.find((permit) => permit.type === 'connection')?.date),
        diaId: technology.permits?.find((permit) => permit.type === 'environmental')?.id,
        diaStatus: technology.permits?.find((permit) => permit.type === 'environmental')?.status,
        diaQuarter: getQuarterFromDate(technology.permits?.find((permit) => permit.type === 'environmental')?.date),
        diaYear: getYearFromDate(technology.permits?.find((permit) => permit.type === 'environmental')?.date),
        aapId: technology.permits?.find((permit) => permit.type === 'previous')?.id,
        aapStatus: technology.permits?.find((permit) => permit.type === 'previous')?.status,
        aapQuarter: getQuarterFromDate(technology.permits?.find((permit) => permit.type === 'previous')?.date),
        aapYear: getYearFromDate(technology.permits?.find((permit) => permit.type === 'previous')?.date),
        aacId: technology.permits?.find((permit) => permit.type === 'construction')?.id,
        aacStatus: technology.permits?.find((permit) => permit.type === 'construction')?.status,
        aacQuarter: getQuarterFromDate(technology.permits?.find((permit) => permit.type === 'construction')?.date),
        aacYear: getYearFromDate(technology.permits?.find((permit) => permit.type === 'construction')?.date),
        otherId: technology.permits?.find((permit) => permit.type === 'other')?.id,
        otherTitle: technology.permits?.find((permit) => permit.type === 'other')?.title,
        otherStatus: technology.permits?.find((permit) => permit.type === 'other')?.status,
        otherQuarter: getQuarterFromDate(technology.permits?.find((permit) => permit.type === 'other')?.date),
        otherYear: getYearFromDate(technology.permits?.find((permit) => permit.type === 'other')?.date),
        permiting_comments: technology.permiting_comments,
      },
    }),
    [technology]
  );

  return (
    <div className="space-y-6">
      <Typography as="p" color="secondary" size="body-3" weight="normal" className="mt-1 italic">
        {t(
          'Please detail all the project permits in this section, including connection, environmental, and construction permits. Indicate whether these permits have been obtained or provide their expected timelines.'
        )}
      </Typography>

      <Form<PermitsFormFields>
        id={id}
        onSubmit={(data) => onSubmit({ permits: normalizeData(data), permiting_comments: data.permiting_comments })}
        options={options}
      >
        {({ formState, register }) => (
          <div className="space-y-6 divide-y">
            <div className="space-y-3">
              <Typography as="h3" size="body-2" color="primary" weight="semibold">
                {t('Connection Permit (IVA)')}
              </Typography>

              <div className="flex space-x-3">
                <Input id="ivaId" type="hidden" registration={register('ivaId')} />

                <Field
                  id="ivaStatus"
                  label={t('Status')}
                  error={formState.errors['ivaStatus']}
                  delay={0}
                  classes={{ field: 'space-y-1 w-1/2' }}
                >
                  <Select
                    id="ivaStatus"
                    options={statusOptions}
                    placeholder={t('Select an option')}
                    color="primary"
                    registration={register('ivaStatus')}
                  />
                </Field>

                <Field id="ivaQuarter" label={t('Expected date')} delay={0} classes={{ field: 'space-y-1 w-1/2' }}>
                  <div className="flex gap-2 space-x-3">
                    <Select
                      id="ivaQuarter"
                      options={quarterOptions}
                      placeholder={t('Select a quarter')}
                      color={formState.errors['ivaQuarter'] ? 'error' : 'primary'}
                      registration={register('ivaQuarter')}
                    />
                    <Input
                      id="ivaYear"
                      type="number"
                      step="1"
                      color={formState.errors['ivaQuarter'] ? 'error' : 'primary'}
                      autoComplete="off"
                      registration={register('ivaYear')}
                    />
                  </div>
                </Field>
              </div>
            </div>

            <div className="space-y-3 pt-6">
              <Typography as="h3" size="body-2" color="primary" weight="semibold">
                {t('Environment permit (DIA)')}
              </Typography>

              <div className="flex space-x-3">
                <Input id="diaId" type="hidden" registration={register('diaId')} />

                <Field
                  id="diaStatus"
                  label={t('Status')}
                  error={formState.errors['diaStatus']}
                  delay={0}
                  classes={{ field: 'space-y-1 w-1/2' }}
                >
                  <Select
                    id="diaStatus"
                    options={statusOptions}
                    placeholder={t('Select an option')}
                    color="primary"
                    registration={register('diaStatus')}
                  />
                </Field>

                <Field
                  id="diaQuarter"
                  label={t('Expected date')}
                  error={formState.errors['diaQuarter']}
                  delay={0}
                  classes={{ field: 'space-y-1 w-1/2' }}
                >
                  <div className="flex gap-2 space-x-3">
                    <Select
                      id="diaQuarter"
                      options={quarterOptions}
                      placeholder={t('Select a quarter')}
                      color={formState.errors['diaQuarter'] ? 'error' : 'primary'}
                      registration={register('diaQuarter')}
                    />
                    <Input
                      id="diaYear"
                      type="number"
                      step="1"
                      color={formState.errors['diaQuarter'] ? 'error' : 'primary'}
                      autoComplete="off"
                      registration={register('diaYear')}
                    />
                  </div>
                </Field>
              </div>
            </div>

            <div className="space-y-3 pt-6">
              <Typography as="h3" size="body-2" color="primary" weight="semibold">
                {t('Previous Permit (AAP)')}
              </Typography>

              <div className="flex space-x-3">
                <Input id="aapId" type="hidden" registration={register('aapId')} />

                <Field
                  id="aapStatus"
                  label={t('Status')}
                  error={formState.errors['aapStatus']}
                  delay={0}
                  classes={{ field: 'space-y-1 w-1/2' }}
                >
                  <Select
                    id="aapStatus"
                    options={statusOptions}
                    placeholder={t('Select an option')}
                    color="primary"
                    registration={register('aapStatus')}
                  />
                </Field>
                <Field id="aapQuarter" label={t('Expected date')} delay={0} classes={{ field: 'space-y-1 w-1/2' }}>
                  <div className="flex gap-2 space-x-3">
                    <Select
                      id="aapQuarter"
                      options={quarterOptions}
                      placeholder={t('Select a quarter')}
                      color={formState.errors['aapQuarter'] ? 'error' : 'primary'}
                      registration={register('aapQuarter')}
                    />
                    <Input
                      id="aapYear"
                      type="number"
                      step="1"
                      color={formState.errors['aapQuarter'] ? 'error' : 'primary'}
                      autoComplete="off"
                      registration={register('aapYear')}
                    />
                  </div>
                </Field>
              </div>
            </div>

            <div className="space-y-3 pt-6">
              <Typography as="h3" size="body-2" color="primary" weight="semibold">
                {t('Construction Permit (AAC)')}
              </Typography>

              <div className="flex space-x-3">
                <Input id="aacId" type="hidden" registration={register('aacId')} />

                <Field
                  id="aacStatus"
                  label={t('Status')}
                  error={formState.errors['aacStatus']}
                  delay={0}
                  classes={{ field: 'space-y-1 w-1/2' }}
                >
                  <Select
                    id="aacStatus"
                    options={statusOptions}
                    placeholder={t('Select an option')}
                    color="primary"
                    registration={register('aacStatus')}
                  />
                </Field>

                <Field id="aacQuarter" label={t('Expected date')} delay={0} classes={{ field: 'space-y-1 w-1/2' }}>
                  <div className="flex gap-2 space-x-3">
                    <Select
                      id="aacQuarter"
                      options={quarterOptions}
                      placeholder={t('Select a quarter')}
                      color={formState.errors['aacQuarter'] ? 'error' : 'primary'}
                      registration={register('aacQuarter')}
                    />
                    <Input
                      id="aacYear"
                      type="number"
                      step="1"
                      color={formState.errors['aacQuarter'] ? 'error' : 'primary'}
                      autoComplete="off"
                      registration={register('aacYear')}
                    />
                  </div>
                </Field>
              </div>
            </div>

            <div className="space-y-3 pt-6">
              <Typography as="h3" size="body-2" color="primary" weight="semibold">
                {t('Other')}
              </Typography>

              <Input id="otherId" type="hidden" registration={register('otherId')} />

              <Field
                id="otherTitle"
                label={t('License name')}
                error={formState.errors['otherTitle']}
                delay={0}
                classes={{ field: 'space-y-1' }}
              >
                <Input
                  id="otherTitle"
                  type="text"
                  color={formState.errors['otherTitle'] ? 'error' : 'primary'}
                  autoComplete="off"
                  registration={register('otherTitle')}
                />
              </Field>

              <div className="flex space-x-3">
                <Field
                  id="otherStatus"
                  label={t('Status')}
                  error={formState.errors['otherStatus']}
                  delay={0}
                  classes={{ field: 'space-y-1 w-1/2' }}
                >
                  <Select
                    id="otherStatus"
                    options={statusOptions}
                    placeholder={t('Select an option')}
                    color="primary"
                    registration={register('otherStatus')}
                  />
                </Field>

                <Field id="otherQuarter" label={t('Expected date')} delay={0} classes={{ field: 'space-y-1 w-1/2' }}>
                  <div className="flex gap-2 space-x-3">
                    <Select
                      id="otherQuarter"
                      options={quarterOptions}
                      placeholder={t('Select a quarter')}
                      color={formState.errors['otherQuarter'] ? 'error' : 'primary'}
                      registration={register('otherQuarter')}
                    />
                    <Input
                      id="otherYear"
                      type="number"
                      step="1"
                      color={formState.errors['otherQuarter'] ? 'error' : 'primary'}
                      autoComplete="off"
                      registration={register('otherYear')}
                    />
                  </div>
                </Field>
              </div>
            </div>
            <Field
              id="comments"
              label={t('Comments')}
              error={formState.errors['permiting_comments']}
              delay={0.45}
              classes={{ field: 'space-y-1 pt-4' }}
            >
              <TextArea
                id="comments"
                color={formState.errors['permiting_comments'] ? 'error' : 'primary'}
                registration={register('permiting_comments', { setValueAs: nullableStringSetter })}
              />
            </Field>
          </div>
        )}
      </Form>
    </div>
  );
}
