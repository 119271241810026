import { useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';

import { DevelopmentStatus, TechnologyType } from '@/features/projects/types';
import { useCurrentUser } from '@/features/users/hooks';
import { searchInStrings } from '@/utils';

import {
  getCapacityFromOpportunity,
  getCountriesFromOpportunity,
  getDevelopmentStatusFromOpportunity,
  getOfferByMeFromOpportunity,
  getTechnologyTypesFromOpportunity,
} from '../helpers';

import { usePublishedOpportunityList } from './use-opportunity-list';

export function useFilteredOpportunities() {
  const [searchParams] = useSearchParams();

  const data = usePublishedOpportunityList();
  const user = useCurrentUser();

  const opportunities = useMemo(
    () =>
      data?.filter((opportunity) => {
        const search = searchParams.get('search');
        const technology = searchParams.get('technology')?.split(',');
        const developmentStatus = searchParams.get('development_status');
        const capacity = searchParams.get('capacity');
        const country = searchParams.get('country');
        const offerer = searchParams.get('company');

        const opportunityTechnologies = getTechnologyTypesFromOpportunity(opportunity);
        const opportunityDevelopmentStatus = getDevelopmentStatusFromOpportunity(opportunity);
        const opportunityCapacity = getCapacityFromOpportunity(opportunity);
        const opportunityCountries = getCountriesFromOpportunity(opportunity);
        const hasOffer = getOfferByMeFromOpportunity(opportunity, user?.company.id);

        if (search && searchInStrings(search, opportunity.name) === false) {
          return false;
        }

        if (
          technology?.some((technology) => opportunityTechnologies.includes(technology as TechnologyType)) === false
        ) {
          return false;
        }

        if (developmentStatus && !opportunityDevelopmentStatus.includes(developmentStatus as DevelopmentStatus)) {
          return false;
        }

        if (capacity) {
          switch (capacity) {
            case '1-5':
              if (opportunityCapacity > 5) return false;
              break;
            case '5-50':
              if (opportunityCapacity < 5 || opportunityCapacity > 50) return false;
              break;
            case '>50':
              if (opportunityCapacity < 50) return false;
              break;
          }
        }

        if (country && !opportunityCountries.includes(country)) {
          return false;
        }

        if (offerer && !hasOffer) {
          return false;
        }

        return true;
      }),
    [searchParams, data, user]
  );

  return {
    opportunities,
  };
}
