import { Deal, DealItemList } from '../types';

export function dealItemListTransformer(deal: Deal): DealItemList {
  return {
    id: deal.id,
    title: deal.title,
    country: deal.country,
    city: deal.city,
    technologies: deal.technologies,
    category: deal.category,
    deadline: new Date(deal.publish_date),
  };
}
