import { useTranslation } from 'react-i18next';

import { Container } from '@/components';
import { useBreadcrumbs } from '@/features/header';
import { MyOffersContent, MyOffersHeader } from '@/features/offers';

export function MyOffers() {
  const { t } = useTranslation(['offers']);

  useBreadcrumbs(() => [{ title: t(`Offers Submitted`) }]);

  return (
    <div className="flex h-full w-full flex-col">
      <Container size="xl">
        <div className="flex flex-col space-y-3 px-4 py-6">
          <MyOffersHeader />
          <MyOffersContent />
        </div>
      </Container>
    </div>
  );
}
