import { Typography } from '@/components';
import { TransactionDetails } from '../../types';
import { useTranslation } from 'react-i18next';
import { TransactionListHeader } from './transaction-list-header';
import { PaginatedTransactionsList } from './transaction-paginated-list-items';

export function TransactionsDetails({ transactions }: { transactions: TransactionDetails[] }) {
  const { t } = useTranslation(['insights']);
  return (
    <div className="space-y-3">
      <Typography as="h1" size="heading-1" color="primary" weight="semibold">
        {t('Recent Transactions')}
      </Typography>
      <Typography as="p" size="body-2" color="secondary" weight="normal">
        {t('Copy description recent transactions')}
      </Typography>

      <div>
        <TransactionListHeader />
        <PaginatedTransactionsList items={transactions} />
      </div>
    </div>
  );
}
