import { useTranslation } from 'react-i18next';

import { EmptyState, WorkOfficeDesk } from '@/components';

export function OpportunitiesEmptyState() {
  const { t } = useTranslation(['opportunities']);

  return (
    <EmptyState
      artwork={<WorkOfficeDesk className="w-64" />}
      title={t('There are currently no opportunities published')}
      description={t(
        'Once opportunities following this criteria have been publish, you will be able to view them all here.'
      )}
    ></EmptyState>
  );
}
