import { IconState } from '@/components';
import { getIconNamesFromTechnologies } from '@/features/projects/helpers';
import { ProjectItemList, TechnologyType, Location } from '@/features/projects/types';

import { Opportunity, OpportunityItemList, Status } from '../types';

export function getTechnologyTypesFromOpportunity(opportunity: OpportunityItemList): TechnologyType[] {
  return opportunity.technologies.types;
}

export function getDevelopmentStatusFromOpportunity(opportunity: OpportunityItemList): string[] {
  return opportunity.technologies.state.list;
}

export function getCountriesFromOpportunity(opportunity: OpportunityItemList): string[] {
  return [...new Set(opportunity.location.map(({ country }) => country))];
}

export function getCapacityFromOpportunity(opportunity: OpportunityItemList): number {
  return opportunity.technologies.capacity;
}

export function getColorFromOpportunityStatus(status: Status) {
  switch (status) {
    case 'draft':
      return 'disabled';
    case 'pending':
      return 'warning';
    case 'published':
      return 'gold';
  }
}

export function getRangeOfPercentage(minimum_percentage: number, maximum_percentage: number) {
  return `${minimum_percentage}% - ${maximum_percentage}%`;
}
export function getIconNamesFromOpportunity(opportunity: Opportunity) {
  return [
    ...new Set(opportunity.projects.flatMap((project) => getIconNamesFromTechnologies(project.project.technologies))),
  ];
}
export function getIconStatesFromOpportunity(opportunity: Opportunity) {
  return getIconNamesFromOpportunity(opportunity).map(
    (iconName) => ({ icon: iconName, status: 'active' }) as IconState
  );
}

export function getUrgencyFromDeadline(deadline: Date) {
  const now = new Date();

  const diff = Math.ceil(deadline.getTime() - now.getTime());
  const days = Math.ceil(diff / (1000 * 3600 * 24));

  if (days < 0) {
    return 'overdue';
  } else if (days < 10) {
    return 'urgent';
  } else if (days < 21) {
    return 'important';
  } else return 'normal';
}

export function getDeadlineStyles(urgency: string) {
  let urgencyClassNames =
    "text-right before:content-[' '] before:mr-2 before:inline-block before:h-[10px] before:w-[10px] before:rounded-full";

  if (urgency === 'urgent') {
    urgencyClassNames += ' before:bg-error-700';
  } else if (urgency === 'important') {
    urgencyClassNames += ' before:bg-warning-400';
  } else if (urgency === 'normal') {
    urgencyClassNames += ' before:bg-primary-700';
  } else {
    urgencyClassNames += ' before:bg-neutral-50';
  }

  return urgencyClassNames;
}

export function getTechnologyTypesFromOpportunityProjects(projects: ProjectItemList[]) {
  return [...new Set(projects.flatMap((project) => project.technologies.types))];
}

export function getLocationFromOpportunityProjects(projects: ProjectItemList[]) {
  const locations = projects.map((project) => project.location);
  const locationsCountries = new Set(locations.map((location) => location.country));

  function _cityInList(itemInList: Location, city: string[]) {
    return itemInList.city.some(
      (item) => item.toString().indexOf(city.join(', ')) !== -1 || city.join(', ').indexOf(item.toString()) !== -1
    );
  }

  const opportunityLocations = [] as Location[];

  locationsCountries.forEach((country) => {
    locations
      .filter((location) => location.country === country)
      .forEach((location) => {
        const itemInList = opportunityLocations.find((location) => location.country === country);
        itemInList
          ? !_cityInList(itemInList, location.city)
            ? itemInList?.city.push(...location.city)
            : null
          : opportunityLocations.push({ country: country, city: [...location.city] });
      });
  });

  return opportunityLocations;
}

export function getDevelopmentStatusFromOpportunityProjects(projects: ProjectItemList[]) {
  return [...new Set(projects.flatMap((project) => project.technologies.state.list))];
}

export function getCapacityFromOpportunityProjects(projects: ProjectItemList[]) {
  return projects.reduce(
    (prev, project) => (project.technologies.capacity ? prev + project.technologies.capacity : prev),
    0
  );
}

export function getOfferByMeFromOpportunity(opportunity: OpportunityItemList, companyId?: string) {
  if (!companyId) return true;
  return opportunity.companiesWhoOffered.includes(companyId);
}

export function getOpportunitiesOrderedByDeadline(opportunities: OpportunityItemList[]) {
  const opportunitiesPaid = opportunities.filter(
    (opportunity) => opportunity.deadline.category !== 'overdue' && opportunity.isPaid
  );
  const opportunitiesActive = opportunities.filter(
    (opportunity) => opportunity.deadline.category !== 'overdue' && !opportunity.isPaid
  );
  const opportunitiesOverdue = opportunities.filter((opportunity) => opportunity.deadline.category === 'overdue');

  return [
    ...opportunitiesPaid.sort((a, b) => a.deadline.date.getTime() - b.deadline.date.getTime()),
    ...opportunitiesActive.sort((a, b) => a.deadline.date.getTime() - b.deadline.date.getTime()),
    ...opportunitiesOverdue.sort((a, b) => b.deadline.date.getTime() - a.deadline.date.getTime()),
  ];
}
