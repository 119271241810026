import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Collapsable, CollapsableBody, CollapsableHeader } from '@/components';
import { displayQuarter } from '@/helpers';
import { isEmpty, isNullish } from '@/types';

import { useCollapsableAction, usePermitStatus } from '../../../hooks';
import { Technology } from '../../../types';

export type PermitCollapsableProps = {
  readonly technology: Technology;
  readonly initialExpandedState?: boolean;
  readonly onEdit?: () => void;
  readonly hidden?: boolean;
};

export function PermitCollapsable({
  technology,
  onEdit,
  initialExpandedState = false,
  hidden,
}: PermitCollapsableProps) {
  const { t } = useTranslation(['projects']);

  const [isExpanded, setIsExpanded] = useState(initialExpandedState);

  const action = useCollapsableAction({ onEdit, setIsExpanded });

  const { map: mapStatus } = usePermitStatus();

  const item = technology.details.permittingPLA;

  const formatPermit = useCallback(
    (permit: { label: string; status?: string; date?: string | null }) => ({
      label: permit.label,
      value: permit.status
        ? `${mapStatus(permit.status)}` + (permit.date ? ` (${displayQuarter(permit.date)})` : '')
        : null,
    }),
    [mapStatus]
  );

  const data = useMemo(() => {
    const iva = item.permits?.find((permit) => permit.type === 'connection');
    const dia = item.permits?.find((permit) => permit.type === 'environmental');
    const aap = item.permits?.find((permit) => permit.type === 'previous');
    const aac = item.permits?.find((permit) => permit.type === 'construction');
    const ivaLabel = iva?.title ? t(`Connection permit (${iva.title})`) : t('Connection permit (IVA)');
    const diaLabel = dia?.title ? t(`Environmental permit (${dia.title})`) : t('Environmental permit (DIA)');
    const aapLabel = aap?.title ? t(`Previous permit (${aap.title})`) : t('Previous permit (AAP)');
    const aacLabel = aac?.title ? t(`Construction permit (${aac.title})`) : t('Construction permit (AAC)');

    const others = item.permits?.filter((permit) => permit.type === 'other');

    return [
      formatPermit({ label: ivaLabel, status: iva?.status, date: iva?.date }),
      formatPermit({ label: diaLabel, status: dia?.status, date: dia?.date }),
      formatPermit({ label: aapLabel, status: aap?.status, date: aap?.date }),
      formatPermit({ label: aacLabel, status: aac?.status, date: aac?.date }),
      ...(others?.map((permit) =>
        formatPermit({
          label: permit.title as string,
          status: permit.status,
          date: permit.date,
        })
      ) ?? []),
      { label: t('Comments'), value: item.comments },
    ];
  }, [item, t, formatPermit]);

  const description = onEdit
    ? t(
        'Please detail all the project permits in this section, including connection, environmental, and construction permits. Indicate whether these permits have been obtained or provide their expected timelines.'
      )
    : t(
        'This section details all the project permits, including connection, environmental, construction, etc., and indicates whether they have been obtained or their expected timelines.'
      );

  const header = (
    <CollapsableHeader
      title={t('Permitting (PLAs)')}
      description={description}
      isExpanded={isExpanded}
      onToggle={() => setIsExpanded((isExpanded) => !isExpanded)}
      action={action}
    />
  );

  const filteredData = isNullish(onEdit) ? data?.filter(({ value }) => !(isNullish(value) || isEmpty(value))) : data;

  return filteredData.length ? (
    <Collapsable index={0} isExpanded={isExpanded} header={header}>
      <CollapsableBody data={filteredData} hidden={hidden} />
    </Collapsable>
  ) : null;
}
