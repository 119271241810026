import { useFilteredOpportunities } from '@/features/opportunities/hooks';
import { useRegisterGAPageView } from '@/hooks';

import { OpportunitiesEmptyState } from '../empty-state';
import { OpportunitiesItems } from '../items';
import { Typography } from '@/components';
import { useTranslation } from 'react-i18next';

export function OpportunitiesContent() {
  const { t } = useTranslation(['opportunities']);
  const { opportunities } = useFilteredOpportunities();
  useRegisterGAPageView('Home');

  if (!opportunities) return null;

  if (opportunities.length === 0) {
    return <OpportunitiesEmptyState />;
  }

  return (
    <div className="space-y-3">
      <Typography as="h1" size="heading-1" color="primary" weight="semibold">
        {t('Projects')}
      </Typography>
      <Typography as="p" size="body-2" color="secondary" weight="normal">
        {t(
          'Explore specific renewable energy projects in Spain, including PV, wind, BESS, and hybrid projects, where you can submit Expressions of Interest.'
        )}
      </Typography>
      <OpportunitiesItems opportunities={opportunities} />
    </div>
  );
}
