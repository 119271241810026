import { useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';

import { TechnologyType } from '@/features/projects/types';
import { searchInStrings } from '@/utils';
import { useDealList } from './use-deals-list';
import { useCountries } from '@/hooks';

export function useFilteredDeals() {
  const [searchParams] = useSearchParams();
  const data = useDealList();
  const { options: countries } = useCountries();

  const deals = useMemo(
    () =>
      data?.filter((deal) => {
        const search = searchParams.get('search');
        const technology = searchParams.get('technology')?.split(',');
        const country = searchParams.get('country');
        const countryLabel = country ? countries?.find((c) => c.value === country)?.label : null;

        if (search && searchInStrings(search, deal.title) === false) {
          return false;
        }

        if (technology?.some((technology) => deal.technologies.includes(technology as TechnologyType)) === false) {
          return false;
        }

        if (countryLabel && !deal.location.includes(countryLabel)) {
          return false;
        }

        return true;
      }),
    [searchParams, data]
  );

  return {
    deals,
  };
}
