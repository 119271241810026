import { useCallback } from 'react';

import { useUpdateProjectMutation } from '../api';

export function useSendForRevision(projectId: string) {
  const [mutation] = useUpdateProjectMutation();

  return useCallback(() => {
    if (!projectId) return;
    mutation({ id: projectId, status: 'pending' });
  }, [projectId, mutation]);
}
