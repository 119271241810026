import { useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import { Button } from '@/components';
import { OpportunityItemList } from '@/features/opportunities/types';
import { cn } from '@/utils/styles';

import { OpportunitiesItem } from '../item/item';

export type PaginatedOpportunitiesListProps = {
  readonly items: OpportunityItemList[];
};

export function PaginatedOpportunitiesList({ items }: PaginatedOpportunitiesListProps) {
  const [searchParams, setSearchParams] = useSearchParams();
  const initialPage = searchParams.get('page') ? parseInt(searchParams.get('page') ?? '1') : 1;

  const [activePage, setActivePage] = useState(initialPage);
  const pages = Math.ceil(items.length / 10);
  const itemsPerPage = 10;
  const displayItems = items.slice((activePage - 1) * itemsPerPage, activePage * itemsPerPage);
  const pagesIndexList = [...Array(pages).keys()];
  const paginationList =
    pagesIndexList.length > 5
      ? pagesIndexList.splice(
          activePage < 3 ? 0 : activePage > pages - 3 ? pages - 5 : activePage - 3, // Only visible 5 links in pagination
          5
        )
      : pagesIndexList;

  const updatePage = (page: number) => {
    setActivePage(page);
    setSearchParams((searchParams) => {
      searchParams.set('page', page.toString());
      return searchParams;
    });
  };

  return (
    <>
      <div className="space-y-3">
        {displayItems.map((opportunity, index) => (
          <OpportunitiesItem key={opportunity.id} index={index} opportunity={opportunity} />
        ))}
      </div>
      <div className="mt-8 flex justify-center">
        <Button
          onClick={() => updatePage(1)}
          color="neutral"
          className="mr-2 bg-neutral-25 enabled:hover:bg-neutral-50"
          disabled={activePage === 1}
        >
          <span>{'<<'}</span>
        </Button>
        {paginationList.map((index) => (
          <Button
            key={index}
            onClick={() => updatePage(index + 1)}
            color="neutral"
            className={cn(
              'mr-2 bg-neutral-25 enabled:hover:bg-neutral-50',
              index + 1 === activePage ? 'bg-neutral-50' : ''
            )}
            disabled={index + 1 === activePage}
          >
            {index + 1}
          </Button>
        ))}
        <Button
          onClick={() => updatePage(pages)}
          color="neutral"
          className="mr-2 bg-neutral-25  enabled:hover:bg-neutral-50"
          disabled={activePage === pages}
        >
          <span>{'>>'}</span>
        </Button>
      </div>
    </>
  );
}
