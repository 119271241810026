import { api } from '@/features/auth';

import {
  CreateProjectRequest,
  GetProjectRequest,
  GetProjectResponse,
  GetProjectsResponse,
  UpdateProjectRequest,
  UpdateProjectTechnologyItemRequest,
  UpdateProjectTechnologyRequest,
} from './types';

export const projectsApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getMyProjects: builder.query<GetProjectsResponse['data'], void>({
      query: () => ({
        url: `items/projects`,
        method: 'GET',
        params: {
          fields: '*,technologies.*.*',
          filter: `{"company": {"users": {"_eq": "$CURRENT_USER"}}, "status": {"_neq": "archived"}}`,
          sort: '-created_at',
        },
      }),
      transformResponse: ({ data }: GetProjectsResponse) => data,
      providesTags: [{ type: 'Projects', id: 'me' }],
    }),
    getProject: builder.query<GetProjectResponse['data'], GetProjectRequest>({
      query: ({ id }: GetProjectRequest) => ({
        url: `items/projects/${id}`,
        method: 'GET',
        params: {
          fields: '*,technologies.*,technologies.item.*,technologies.permits.*,technologies.files.file.*',
        },
      }),
      transformResponse: ({ data }: GetProjectResponse) => data,
      providesTags: (_result, _error, args) => [{ type: 'Projects', id: args.id }],
    }),
    createProject: builder.mutation<GetProjectResponse['data'], CreateProjectRequest>({
      query: (data: CreateProjectRequest) => ({
        url: `items/projects`,
        method: 'POST',
        body: data,
      }),
      transformResponse: ({ data }: GetProjectResponse) => data,
      invalidatesTags: [{ type: 'Projects', id: 'me' }],
    }),
    updateProject: builder.mutation<void, UpdateProjectRequest>({
      query: ({ id, ...data }: UpdateProjectRequest) => ({
        url: `items/projects/${id}`,
        method: 'PATCH',
        body: data,
      }),
      invalidatesTags: (_result, _error, args) => [
        { type: 'Projects', id: 'me' },
        { type: 'Projects', id: args.id },
        { type: 'Opportunities', id: args.opportunityId },
      ],
    }),
    updateProjectTechnology: builder.mutation<void, UpdateProjectTechnologyRequest>({
      query: ({ id, data }: UpdateProjectTechnologyRequest) => ({
        url: `items/projects_technologies/${id}`,
        method: 'PATCH',
        body: data,
      }),
      invalidatesTags: (_result, _error, args) => [
        { type: 'Projects', id: 'me' },
        { type: 'Projects', id: args.projectId },
        { type: 'Opportunities', id: args.opportunityId },
      ],
    }),
    updateProjectTechnologyItem: builder.mutation<void, UpdateProjectTechnologyItemRequest>({
      query: ({ collection, data: { id, ...data } }: UpdateProjectTechnologyItemRequest) => ({
        url: `items/${collection}/${id}`,
        method: 'PATCH',
        body: data,
      }),
      invalidatesTags: (_result, _error, args) => [
        { type: 'Projects', id: 'me' },
        { type: 'Projects', id: args.projectId },
        { type: 'Opportunities', id: args.opportunityId },
      ],
    }),
  }),
});

export const {
  useGetProjectQuery,
  useUpdateProjectMutation,
  useUpdateProjectTechnologyMutation,
  useUpdateProjectTechnologyItemMutation,
  useGetMyProjectsQuery,
  useCreateProjectMutation,
} = projectsApi;

export * from './types';
