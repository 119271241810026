import { useTranslation } from 'react-i18next';

import { Button, EmptyState, SearchOpportunities } from '@/components';
import { useOnClickViewOpportunities } from '@/features/offers/hooks';

export function MyOffersEmptyState() {
  const { t } = useTranslation(['offers']);
  const handleOnClick = useOnClickViewOpportunities();

  return (
    <EmptyState
      artwork={<SearchOpportunities className="w-64" />}
      title={t('Here you will see all your submitted offers')}
      description={t('Click on the button below to see all opportunities.')}
    >
      <Button size="lg" color="primary" icon="plus" onClick={handleOnClick}>
        {t('View opportunities')}
      </Button>
    </EmptyState>
  );
}
