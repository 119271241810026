import { AnimationShell, Button, Icons, IconState, Typography } from '@/components';
import { cn } from '@/utils/styles';

import { useDealDetails } from '../../hooks';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { ASSETS_BASE_URL } from '@/config';
import { useCountries } from '@/hooks';
import { useSendDealsStats } from '@/features/stats';

export type DealHeaderViewProps = {
  readonly id: string;
  readonly setIsOpen: (isOpen: boolean) => void;
};

export function DealHeaderView({ id, setIsOpen }: DealHeaderViewProps) {
  const deal = useDealDetails(id);
  const { t } = useTranslation(['deals']);
  const technologies = deal?.technologies.map((technology) => ({ icon: technology, status: 'active' }) as IconState);
  const sendStats = useSendDealsStats({ type: 'download_teaser' });

  const handleOnClick = useCallback(() => setIsOpen(true), []);
  const handleOnClickTeaser = useCallback(() => {
    if (deal) sendStats(deal.id);
    window.open(`${ASSETS_BASE_URL}/${deal?.teaserId}`, '_blank');
  }, [deal]);

  const { options: countries } = useCountries();
  const country = countries.find((c) => c.value === deal?.country)?.label || deal?.country;
  const location = `${country} (${deal?.city})`;

  if (!deal) return null;

  return (
    <AnimationShell type="opacity" className={cn('mb-6 shrink-0 space-y-4')}>
      <div className="inline-flex w-full items-center justify-between gap-4">
        <div className="inline-flex items-center justify-center gap-2">
          {technologies && <Icons size="xl" icons={technologies} />}
        </div>
        <div className="inline-flex items-center justify-start gap-2">
          <Button size="sm" color="secondary" icon="check" onClick={handleOnClick}>
            {t('Express interest')}{' '}
          </Button>
          {deal.teaserId && (
            <Button size="sm" color="neutral" icon="download" onClick={handleOnClickTeaser} className="px-6 py-2">
              {t('Download Teaser')}{' '}
            </Button>
          )}
        </div>
      </div>

      <AnimationShell type="slide-left">
        <Typography as="h1" size="heading-1" color="primary" weight="semibold">
          {deal.title}
        </Typography>
        <Typography as="p" size="body-2" color="secondary" weight="normal">
          {location}
        </Typography>
      </AnimationShell>
    </AnimationShell>
  );
}
