import { motion } from 'framer-motion';

import { AnimationShell, Icons, IconState, Link, Typography } from '@/components';
import { Route } from '@/constants';
import { DealItemList } from '@/features/deals/types';
import { IconName } from '@/components/icons/types';
import { useCountries } from '@/hooks';

export type MyOpportunitiesItemProps = {
  readonly deal: DealItemList;
  readonly index?: number;
};

export function DealsItem({ deal, index = 0 }: Readonly<MyOpportunitiesItemProps>) {
  const technologies = deal.technologies.map(
    (technology) => ({ icon: technology as IconName, status: 'active' }) as IconState
  );
  const { options: countries } = useCountries();
  const country = countries.find((c) => c.value === deal.country)?.label || deal.country;

  return (
    <motion.div
      initial={{ y: 0 }}
      whileHover={{ y: -6 }}
      transition={{ type: 'spring', stiffness: 100 }}
      className="w-full"
    >
      <Link to={Route.Deal.replace(':dealId', deal.id)} className="hover:no-underline">
        <AnimationShell
          type="scale"
          delay={0.1 * index}
          className="h-full w-fit cursor-pointer rounded-md border border-neutral-50 bg-base-white p-4 opacity-100 shadow-sm transition hover:shadow-xl"
        >
          <div className="group flex items-center justify-between space-x-1.5">
            <div className="w-20 shrink-0">
              <Icons icons={technologies} />
            </div>
            <div className="w-[38rem] shrink-0">
              <Typography as="span" size="body-2" color="primary" weight="semibold" className="truncate">
                {deal.title}
              </Typography>
            </div>

            <div className="w-48 shrink-0">
              <Typography as="span" size="body-3" color="primary" weight="normal">
                {country} ({deal.city})
              </Typography>
            </div>

            <div className="w-52 shrink-0">
              <Typography as="span" size="body-3" color="primary" weight="normal">
                Deadline:{' '}
                {deal.deadline.toLocaleDateString('en-US', { day: 'numeric', month: 'short', year: 'numeric' })}
              </Typography>
            </div>
          </div>
        </AnimationShell>
      </Link>
    </motion.div>
  );
}
