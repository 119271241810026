import { useTranslation } from 'react-i18next';

import { Aside, Container } from '@/components';
import { useBreadcrumbs } from '@/features/header';
import { OpportunitiesContent, OpportunitiesFilter } from '@/features/opportunities';

export function Opportunities() {
  const { t } = useTranslation(['opportunities']);
  useBreadcrumbs(() => [{ title: t(`Projects`) }]);

  return (
    <div className="inline-flex h-full w-full items-start justify-start overflow-hidden">
      <Aside>
        <OpportunitiesFilter />
      </Aside>

      <div className="mx-auto h-full w-full overflow-x-auto bg-neutral-25 py-8">
        <Container className="mx-auto w-fit" overflow={true}>
          <OpportunitiesContent />
        </Container>
      </div>
    </div>
  );
}
