import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { Field, Form, Input, Select, TextArea, Typography } from '@/components';
import { SolarProjectTechnology } from '@/features/projects/types';
import { getDateFromQuarterAndYear, getQuarterFromDate, getYearFromDate, nullableStringSetter } from '@/helpers';

export type SolarGeneralFormFields = {
  installed_capacity_peak: number | null;
  installed_capacity_nominal: number | null;
  ready_to_build: string | null;
  ready_to_build_quarter: string | null;
  ready_to_build_year: number | null;
  commercial_operation_date: string | null;
  commercial_operation_date_quarter: string | null;
  commercial_operation_date_year: number | null;
  specific_production: number | null;
  comments: string | null;
};

export type SolarGeneralFormProps = {
  readonly id: string;
  readonly item: SolarProjectTechnology;
  readonly onSubmit: (data: SolarGeneralFormFields) => void;
};

export function SolarGeneralForm({ id, item, onSubmit }: SolarGeneralFormProps) {
  const { t } = useTranslation(['projects']);
  const quarterOptions = [
    { label: 'Q1', value: '1' },
    { label: 'Q2', value: '2' },
    { label: 'Q3', value: '3' },
    { label: 'Q4', value: '4' },
  ];

  const options = useMemo(
    () => ({
      defaultValues: {
        installed_capacity_peak: item.installed_capacity_peak,
        installed_capacity_nominal: item.installed_capacity_nominal,
        ready_to_build: item.ready_to_build,
        ready_to_build_quarter: getQuarterFromDate(item.ready_to_build),
        ready_to_build_year: getYearFromDate(item.ready_to_build),
        commercial_operation_date_quarter: getQuarterFromDate(item.commercial_operation_date),
        commercial_operation_date_year: getYearFromDate(item.commercial_operation_date),
        commercial_operation_date: item.commercial_operation_date,
        specific_production: item.specific_production,
        comments: item.comments,
      },
    }),
    [item]
  );

  const onSubmitGeneralForm = useCallback(
    (data: SolarGeneralFormFields) => {
      data.ready_to_build = getDateFromQuarterAndYear(data.ready_to_build_quarter, data.ready_to_build_year);

      data.commercial_operation_date = getDateFromQuarterAndYear(
        data.commercial_operation_date_quarter,
        data.commercial_operation_date_year
      );

      onSubmit(data);
    },
    [onSubmit]
  );

  return (
    <Form<SolarGeneralFormFields> id={id} onSubmit={(data) => onSubmitGeneralForm(data)} options={options}>
      {({ formState, register }) => (
        <div className="space-y-6">
          <Typography as="p" color="secondary" size="body-3" weight="normal" className="mt-1 italic">
            {t(
              'Please fill in this section with the main details about the project, including technology, development status, location, installed capacity, ready-to-build (RtB) and COD dates, etc.'
            )}
          </Typography>
          <Field
            id="installed_capacity_peak"
            label={t('Installed Capacity Peak')}
            error={formState.errors['installed_capacity_peak']}
            delay={0}
            classes={{ field: 'space-y-1' }}
          >
            <Input
              id="installed_capacity_peak"
              type="number"
              suffix="MWp"
              placeholder={t('Example: 300')}
              color={formState.errors['installed_capacity_peak'] ? 'error' : 'primary'}
              autoComplete="off"
              registration={register('installed_capacity_peak', {
                valueAsNumber: true,
              })}
            />
          </Field>

          <Field
            id="installed_capacity_nominal"
            label={t('Installed Capacity Nominal')}
            error={formState.errors['installed_capacity_nominal']}
            delay={0.15}
            classes={{ field: 'space-y-1' }}
          >
            <Input
              id="installed_capacity_nominal"
              type="number"
              suffix="MWn"
              placeholder={t('Example: 379')}
              color={formState.errors['installed_capacity_nominal'] ? 'error' : 'primary'}
              autoComplete="off"
              registration={register('installed_capacity_nominal', { valueAsNumber: true })}
            />
          </Field>

          <Field
            id="ready_to_build"
            label={t('Ready to Build Date')}
            error={formState.errors['ready_to_build']}
            delay={0.25}
            classes={{ field: 'space-y-1 w-1/2' }}
          >
            <div className="flex gap-2 space-x-3">
              <Select
                id="ready_to_build_quarter"
                options={quarterOptions}
                placeholder={t('Select a quarter')}
                color={formState.errors['ready_to_build_quarter'] ? 'error' : 'primary'}
                registration={register('ready_to_build_quarter')}
              />
              <Input
                id="ready_to_build_year"
                type="number"
                step="1"
                color={formState.errors['ready_to_build_year'] ? 'error' : 'primary'}
                autoComplete="off"
                registration={register('ready_to_build_year', { valueAsNumber: true })}
              />
            </div>
          </Field>

          <Field
            id="commercial_operation_date"
            label={t('Commercial Operation Date')}
            error={formState.errors['ready_to_build']}
            delay={0.25}
            classes={{ field: 'space-y-1 w-1/2' }}
          >
            <div className="flex gap-2 space-x-3">
              <Select
                id="commercial_operation_date_quarter"
                options={quarterOptions}
                placeholder={t('Select a quarter')}
                color={formState.errors['commercial_operation_date_quarter'] ? 'error' : 'primary'}
                registration={register('commercial_operation_date_quarter')}
              />
              <Input
                id="commercial_operation_date_year"
                type="number"
                step="1"
                color={formState.errors['commercial_operation_date_year'] ? 'error' : 'primary'}
                autoComplete="off"
                registration={register('commercial_operation_date_year', { valueAsNumber: true })}
              />
            </div>
          </Field>

          <Field
            id="specific_production"
            label={t('Specific production')}
            error={formState.errors['specific_production']}
            delay={0.45}
            classes={{ field: 'space-y-1' }}
          >
            <Input
              id="specific_production"
              type="number"
              suffix="MWh/MWp"
              placeholder={t('Example: 379')}
              color={formState.errors['specific_production'] ? 'error' : 'primary'}
              autoComplete="off"
              registration={register('specific_production', { valueAsNumber: true })}
            />
          </Field>
          <Field
            id="comments"
            label={t('Comments')}
            error={formState.errors['comments']}
            delay={0.45}
            classes={{ field: 'space-y-1' }}
          >
            <TextArea
              id="comments"
              color={formState.errors['comments'] ? 'error' : 'primary'}
              registration={register('comments', { setValueAs: nullableStringSetter })}
            />
          </Field>
        </div>
      )}
    </Form>
  );
}
