import { IconState } from '@/components';
import { IconName } from '@/components/icons/types';

import {
  BESSProjectTechnology,
  isSolarProjectTechnology,
  ProjectStatus,
  ProjectTechnology,
  SolarProjectTechnology,
  TechnologyCollection,
  TechnologyType,
  WindProjectTechnology,
} from '../types';

export function getTechnologyTypeFromCollection(collection: TechnologyCollection): TechnologyType {
  switch (collection) {
    case 'projects_bess_technologies':
      return 'bess';
    case 'projects_solar_technologies':
      return 'solar';
    case 'projects_wind_technologies':
      return 'wind';
  }
}

export function getTechnologyTypesFromTechnologies(technologies: ProjectTechnology[]) {
  return technologies.map((technology) => getTechnologyTypeFromCollection(technology.collection));
}

export function getDevelopmentStatusFromTechnology(technologies: ProjectTechnology[]) {
  return technologies.map(({ item }) => item.development_status);
}

export function getCapacityFromSolarTechnology(technology: SolarProjectTechnology) {
  return technology.installed_capacity_peak ?? 0;
}

export function getCapacityFromWindAndBESSTechnology(technology: WindProjectTechnology | BESSProjectTechnology) {
  return technology.installed_capacity ?? 0;
}

export function getCapacityFromTechnologies(technologies: ProjectTechnology[]) {
  return technologies
    .map(({ item }) =>
      isSolarProjectTechnology(item)
        ? getCapacityFromSolarTechnology(item as SolarProjectTechnology)
        : getCapacityFromWindAndBESSTechnology(item as WindProjectTechnology | BESSProjectTechnology)
    )
    .reduce((prev, item) => (prev ?? 0) + (item ?? 0), 0);
}

export function getColorFromProjectStatus(status: ProjectStatus) {
  switch (status) {
    case 'draft':
      return 'disabled';
    case 'pending':
      return 'warning';
    case 'published':
      return 'info';
    case 'archived':
      return 'disabled';
  }
}

export function getIconByCollection(collection: TechnologyCollection): IconName {
  switch (collection) {
    case 'projects_bess_technologies':
      return 'bess';
    case 'projects_solar_technologies':
      return 'solar';
    case 'projects_wind_technologies':
      return 'wind';
  }
}

export function getIconNamesFromTechnologies(technologies: ProjectTechnology[]) {
  return technologies.map(({ collection }) => getIconByCollection(collection));
}

export function getIconStatesFromTechnologies(technologies: ProjectTechnology[]) {
  return getIconNamesFromTechnologies(technologies).map((icon) => ({ icon, status: 'active' }) as IconState);
}

export function isDraft(status?: string) {
  return status === 'draft';
}

export function isPublished(status?: string) {
  return status === 'published';
}
