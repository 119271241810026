import { useTranslation } from 'react-i18next';

import { Button, Drawer } from '@/components';
import { Permit, ProjectTechnology } from '@/features/projects/types';

import { PermitsForm } from './permits-form';

export type PermitsDrawerProps = {
  readonly technology: ProjectTechnology;
  readonly isOpen: boolean;
  readonly onSubmit: (
    data: { permits: Permit[]; permitting_comments?: string | null },
    description?: string | null
  ) => void;
  readonly onClose: () => void;
};

export function PermitsDrawer({ technology, isOpen, onSubmit, onClose }: PermitsDrawerProps) {
  const { t } = useTranslation(['projects']);

  const footer = (
    <>
      <Button size="lg" color="neutral" onClick={onClose}>
        {t('Cancel')}
      </Button>
      <Button type="submit" form="permits-form" size="lg" color="primary">
        {t('Save')}
      </Button>
    </>
  );

  return (
    <Drawer title={t('Permitting (PLAs)')} open={isOpen} onClose={onClose} footer={footer}>
      <PermitsForm
        id="permits-form"
        technology={technology}
        onSubmit={(data) => {
          onSubmit(data, t('Permits section has been updated'));
        }}
      />
    </Drawer>
  );
}
