import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { Checkbox, Field, Form, Input, Select, TextArea, Typography } from '@/components';
import { useDUPStatus } from '@/features/projects/hooks/use-dup-status';
import { DUPStatus, LandType, ProjectTechnology } from '@/features/projects/types';
import {
  formatStringAsInteger,
  nullableStringSetter,
  parseStringToInteger,
  getDateFromQuarterAndYear,
  getQuarterFromDate,
  getYearFromDate,
} from '@/helpers';

export type LandRightsFormFields = {
  land_surface: number | null;
  land_types: LandType[] | null;
  land_price: number | null;
  land_contract_duration: string | null;
  land_contracted_percentage: number | null;
  dup_status: DUPStatus | null;
  dup_date: string | null;
  dup_date_quarter: string | null;
  dup_date_year: number | null;
  dup_comments: string | null;
  land_comments: string | null;
};

export type LandRightsFormProps = {
  readonly id: string;
  readonly technology: ProjectTechnology;
  readonly onSubmit: (data: Partial<ProjectTechnology>) => void;
};

export function LandRightsForm({ id, technology, onSubmit }: LandRightsFormProps) {
  const { t } = useTranslation(['projects']);

  const { options: statusOptions } = useDUPStatus();
  const quarterOptions = [
    { label: 'Q1', value: '1' },
    { label: 'Q2', value: '2' },
    { label: 'Q3', value: '3' },
    { label: 'Q4', value: '4' },
  ];

  const options = useMemo(
    () => ({
      defaultValues: {
        land_surface: technology.land_surface,
        land_types: technology.land_types ?? [],
        land_price: technology.land_price,
        land_contract_duration: technology.land_contract_duration,
        land_contracted_percentage: technology.land_contracted_percentage,
        land_comments: technology.land_comments,
        dup_status: technology.dup_status,
        dup_date: technology.dup_date,
        dup_date_quarter: getQuarterFromDate(technology.dup_date),
        dup_date_year: getYearFromDate(technology.dup_date),
        dup_comments: technology.dup_comments,
      },
    }),
    [technology]
  );

  const onSubmitLandRightsForm = useCallback(
    (data: LandRightsFormFields) => {
      data.dup_date = getDateFromQuarterAndYear(data.dup_date_quarter, data.dup_date_year);
      onSubmit(data);
    },
    [onSubmit]
  );

  return (
    <Form<LandRightsFormFields> id={id} onSubmit={(data) => onSubmitLandRightsForm(data)} options={options}>
      {({ formState, register }) => (
        <div className="space-y-6">
          <Typography as="p" color="secondary" size="body-3" weight="normal" className="mt-1 italic">
            {t(
              "Please provide detailed information about the project's land rights in this section, including surface area, type and terms of the agreement, and any additional information related to the lands."
            )}
          </Typography>
          <Field
            id="land_surface"
            label={t('Surface')}
            error={formState.errors['land_surface']}
            delay={0}
            classes={{ field: 'space-y-1' }}
          >
            <Input
              id="land_surface"
              type="number"
              step="0.1"
              placeholder={t('e.g. 200')}
              color="primary"
              suffix="ha"
              registration={register('land_surface', { valueAsNumber: true })}
            />
          </Field>

          <Field label={t('Type')} delay={0} classes={{ field: 'space-y-2', input: 'flex space-x-3' }}>
            <Checkbox color="primary" registration={register('land_types')} value="rental">
              {t('Rental')}
            </Checkbox>
            <Checkbox color="primary" registration={register('land_types')} value="purchase">
              {t('Purchase')}
            </Checkbox>
            <Checkbox color="primary" registration={register('land_types')} value="other">
              {t('Other')}
            </Checkbox>
          </Field>

          <Field
            id="land_price"
            label={t('Price')}
            error={formState.errors['land_price']}
            delay={0}
            classes={{ field: 'space-y-1' }}
          >
            <Input
              id="land_price"
              type="text"
              mask={formatStringAsInteger}
              placeholder={t('e.g. 200')}
              color="primary"
              suffix="€/ha"
              registration={register('land_price', { setValueAs: parseStringToInteger })}
            />
          </Field>

          <Field
            id="land_contracted_percentage"
            label={t('Contracted percentage')}
            error={formState.errors['land_contracted_percentage']}
            delay={0}
            classes={{ field: 'space-y-1' }}
          >
            <Input
              id="land_contracted_percentage"
              type="number"
              placeholder={t('e.g. 200')}
              color="primary"
              suffix="% contracted"
              registration={register('land_contracted_percentage', { min: 0, max: 100, valueAsNumber: true })}
            />
          </Field>

          <Field
            id="land_contract_duration"
            label={t('Contract duration')}
            error={formState.errors['land_contract_duration']}
            delay={0}
            classes={{ field: 'space-y-1' }}
          >
            <Input
              id="land_contract_duration"
              type="text"
              placeholder={t('e.g. 30 years + 5 + 5')}
              color="primary"
              registration={register('land_contract_duration', { setValueAs: nullableStringSetter })}
            />
          </Field>

          <Field
            id="dup_status"
            label={t('DUP Status')}
            error={formState.errors['dup_status']}
            delay={0}
            classes={{ field: 'space-y-1' }}
          >
            <Select
              id="dup_status"
              options={statusOptions}
              placeholder={t('Select an option')}
              color="primary"
              registration={register('dup_status', { setValueAs: nullableStringSetter })}
            />
          </Field>

          <Field
            id="dup_date"
            label={t('DUP Status')}
            error={formState.errors['dup_date']}
            delay={0}
            classes={{ field: 'space-y-1 w-1/2' }}
          >
            <div className="flex gap-2 space-x-3">
              <Select
                id="dup_date_quarter"
                options={quarterOptions}
                placeholder={t('Select a quarter')}
                color={formState.errors['dup_date_quarter'] ? 'error' : 'primary'}
                registration={register('dup_date_quarter')}
              />
              <Input
                id="dup_date_year"
                type="number"
                step="1"
                color={formState.errors['dup_date_year'] ? 'error' : 'primary'}
                autoComplete="off"
                registration={register('dup_date_year', { valueAsNumber: true })}
              />
            </div>
          </Field>

          <Field
            id="dup_comments"
            label={t('DUP Comments')}
            error={formState.errors['dup_comments']}
            delay={0}
            classes={{ field: 'space-y-1' }}
          >
            <TextArea
              id="dup_comments"
              color="primary"
              registration={register('dup_comments', { setValueAs: nullableStringSetter })}
            />
          </Field>

          <Field
            id="land_comments"
            label={t('Comments')}
            error={formState.errors['land_comments']}
            delay={0}
            classes={{ field: 'space-y-1' }}
          >
            <TextArea
              id="land_comments"
              color="primary"
              registration={register('land_comments', { setValueAs: nullableStringSetter })}
            />
          </Field>
        </div>
      )}
    </Form>
  );
}
