import { useCallback, useMemo } from 'react';
import ReactGa from 'react-ga4';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { Route } from '@/constants';
import { useNotifications } from '@/features/notifications';
import { CreateProjectRequest } from '@/features/projects';
import { TechnologyCollection, TechnologyType } from '@/features/projects/types';
import { useGetCurrentUserQuery } from '@/features/users';

import { useCreateOpportunityMutation } from '../api';
import { CreateOpportunityFormFields } from '../components';

export function useOnCreateOpportunity(setIsOpen: (arg0: boolean) => void) {
  const { t } = useTranslation(['opportunities']);
  const { data: user } = useGetCurrentUserQuery();

  const [createOpportunity, { isLoading: isLoading }] = useCreateOpportunityMutation();

  const { add } = useNotifications();
  const navigate = useNavigate();

  const collections: Record<TechnologyType, TechnologyCollection> = useMemo(
    () => ({
      bess: 'projects_bess_technologies',
      solar: 'projects_solar_technologies',
      wind: 'projects_wind_technologies',
    }),
    []
  );

  return useCallback(
    async (data: CreateOpportunityFormFields) => {
      if (!user) return;
      if (isLoading) return;

      createOpportunity({
        company: user.company.id,
        name: data.name,
        deadline: data.deadline.toISOString(),
        minimum_percentage: data.minimum_percentage,
        maximum_percentage: data.maximum_percentage,
        options: data.options,
        comments: data.comments,
        projects: [
          {
            project: {
              company: user.company.id,
              title: data.name,
              country: data.country,
              city: data.city,
              technologies: ['solar' as const, 'wind' as const, 'bess' as const]
                .filter((type) => data[type].enabled)
                .map((type) => ({
                  collection: collections[type],
                  item: {
                    development_status: data[type].developmentStatus,
                  },
                })) as CreateProjectRequest['technologies'],
            },
          },
        ],
        about_you: data.about_you,
        advisory_service_information: data.advisory_service_information,
      })
        .unwrap()
        .then((response) => {
          add({
            id: new Date().toString(),
            title: t('New Opportunity created'),
            description: t('Your new opportunity has been created'),
          });
          ReactGa.event({
            category: 'form_submitted',
            action: 'Opportunity created',
            label: 'User has created a new opportunity.',
          });
          navigate([Route.Opportunity.replace(':opportunityId', response.data.id), 'tab=1'].join('?'));
        })
        .catch(() => {
          add({
            id: new Date().toString(),
            title: t('ERROR'),
            description: t('There was an error creating the opportunity. Please try again later.'),
          });
        })

        .finally(() => setIsOpen(false));
    },
    [user, isLoading, t, add, setIsOpen, createOpportunity, collections, navigate]
  );
}
