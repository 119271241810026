import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Collapsable, CollapsableBody, CollapsableHeader } from '@/components';
import { OpportunityDetails } from '@/features/opportunities/types';
import { useBelongsToMyCompany, useCompanyRole } from '@/features/company/hooks';

export type AboutTheSellerCollapsableProps = {
  readonly opportunity: OpportunityDetails;
  readonly initialExpandedState?: boolean;
};

export function AboutTheSellerCollapsable({
  opportunity,
  initialExpandedState = true,
}: AboutTheSellerCollapsableProps) {
  const { t } = useTranslation(['opportunities']);
  const opportunityBelongsToMyCompany = useBelongsToMyCompany(opportunity.company);
  const { isPremium } = useCompanyRole();

  const [isExpanded, setIsExpanded] = useState(initialExpandedState);
  const isVisible = opportunityBelongsToMyCompany || isPremium || opportunity.isPaid;

  const header = (
    <CollapsableHeader
      title={t('About the Seller')}
      description={t(
        "This section provides an anonymous summary of the seller's relevant experience in the industry, ensuring you understand their expertise and track record in the renewable energy sector."
      )}
      isExpanded={isExpanded}
      onToggle={() => setIsExpanded((isExpanded) => !isExpanded)}
    />
  );

  return (
    <Collapsable index={0} isExpanded={isExpanded} header={header}>
      <CollapsableBody data={[{ label: t('Description'), value: opportunity.aboutYou }]} hidden={!isVisible} />
    </Collapsable>
  );
}
