import { useTranslation } from 'react-i18next';

import { Button, Drawer } from '@/components';
import {
  BESSProjectTechnology,
  ProjectTechnology,
  SolarProjectTechnology,
  TechnologyCollection,
  WindProjectTechnology,
} from '@/features/projects/types';

import { BESSGeneralForm, BESSGeneralFormFields } from './bess-form';
import { SolarGeneralForm, SolarGeneralFormFields } from './solar-form';
import { WindGeneralForm, WindGeneralFormFields } from './wind-form';

export type GeneralDrawerProps = {
  readonly technology: ProjectTechnology;
  readonly isOpen: boolean;
  readonly onSubmit: (
    collection: TechnologyCollection,
    item: SolarProjectTechnology | WindProjectTechnology | BESSProjectTechnology,
    description?: string
  ) => void;
  readonly onClose: () => void;
};

export function GeneralDrawer({ technology, isOpen, onSubmit, onClose }: GeneralDrawerProps) {
  const { t } = useTranslation(['projects']);

  const footer = (
    <>
      <Button size="lg" color="neutral" onClick={onClose}>
        {t('Cancel')}
      </Button>
      <Button type="submit" form="general-form" size="lg" color="primary">
        {t('Save')}
      </Button>
    </>
  );

  return (
    <Drawer title={t('General Information')} open={isOpen} onClose={onClose} footer={footer}>
      {technology.collection === 'projects_solar_technologies' ? (
        <SolarGeneralForm
          id="general-form"
          item={technology.item as SolarProjectTechnology}
          onSubmit={(data: SolarGeneralFormFields) =>
            onSubmit(
              'projects_solar_technologies',
              { ...technology.item, ...data },
              t('General section has been updated')
            )
          }
        />
      ) : null}
      {technology.collection === 'projects_wind_technologies' ? (
        <WindGeneralForm
          id="general-form"
          item={technology.item as WindProjectTechnology}
          onSubmit={(data: WindGeneralFormFields) =>
            onSubmit(
              'projects_wind_technologies',
              { ...technology.item, ...data },
              t('General section has been updated')
            )
          }
        />
      ) : null}
      {technology.collection === 'projects_bess_technologies' ? (
        <BESSGeneralForm
          id="general-form"
          item={technology.item as BESSProjectTechnology}
          onSubmit={(data: BESSGeneralFormFields) =>
            onSubmit(
              'projects_bess_technologies',
              { ...technology.item, ...data },
              t('General section has been updated')
            )
          }
        />
      ) : null}
    </Drawer>
  );
}
