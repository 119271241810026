import { useTranslation } from 'react-i18next';

import { Container, AnimationShell, Icons, Typography, Button, Chip } from '@/components';
import { ChipColor } from '@/components/chips/types';
import { useLocation, useProjectHeaderDetails, useProjectStatus, useSendForRevision } from '@/features/projects/hooks';
import { useRegisterGAPageView } from '@/hooks';
import { cn } from '@/utils/styles';
import { useBelongsToMyCompany } from '@/features/company/hooks';

export type ProjectHeaderProps = {
  readonly id: string;
};

// TODO-PROJECTS : REMOVE THIS VIEW - DO NOT USE

export function ProjectViewHeader({ id }: ProjectHeaderProps) {
  const { t } = useTranslation(['projects']);
  const project = useProjectHeaderDetails(id);

  useRegisterGAPageView(project ? `Project ${project.title}` : null);

  useRegisterGAPageView(project ? `Project ${project.title}` : null);

  const sendForRevision = useSendForRevision(id);

  const isDraft = project?.status.name === 'draft';
  const projectBelongsToMyCompany = useBelongsToMyCompany(project?.company);
  const status = useProjectStatus(project?.status.name);
  const location = useLocation({ location: project ? [project.location] : [] });

  if (!project) return null;

  return (
    <Container className="space-y-6 pb-4 pt-6">
      <AnimationShell type="opacity" className={cn('shrink-0 space-y-3')}>
        <Icons size="lg" icons={project?.technologies.icons} />

        <div className="flex items-end justify-between">
          <AnimationShell type="slide-left" className="flex flex-col space-y-2">
            <Typography as="h1" size="heading-1" color="primary" weight="semibold">
              {project.title}
            </Typography>

            <Typography as="p" size="body-2" color="primary" weight="normal">
              {location}
            </Typography>
          </AnimationShell>

          <AnimationShell type="slide-left">
            {projectBelongsToMyCompany && isDraft ? (
              <Button size="md" color="primary" onClick={sendForRevision}>
                {t('Send for revision')}
              </Button>
            ) : null}

            {!projectBelongsToMyCompany || !isDraft ? (
              <Chip color={project.status.color as ChipColor}> {status} </Chip>
            ) : null}
          </AnimationShell>
        </div>
      </AnimationShell>
    </Container>
  );
}
