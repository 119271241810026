import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { AnimationShell, Button, Chip, Icons, Typography } from '@/components';
import { ChipColor } from '@/components/chips/types';
import { Route } from '@/constants';
import { useLocation } from '@/features/projects/hooks';
import { GoUpgradeModal } from '@/features/users';
import { isBoolean } from '@/types';
import { cn } from '@/utils/styles';

import { useIsOpportunityEnabled, useOpportunityDetails, useSendForRevision } from '../../hooks';

import { OpportunityCards } from './opportunity-cards';
import { OpportunityHeaderTabs } from './opportunity-header-tabs';
import { OpportunityStats } from './opportunity-offers-view/opportunity-stats';
import { useBelongsToMyCompany, useCompanyRole } from '@/features/company/hooks';
import { ASSETS_BASE_URL } from '@/config';
import { useSendOpportunityStats } from '@/features/stats';
import { useNotifications } from '@/features/notifications';

export type OpportunityHeaderViewProps = {
  readonly id: string;
  readonly setIsOpen: (isOpen: boolean) => void;
  readonly tabActive: number;
  readonly onTabChange: (index: number) => void;
};

export function OpportunityHeaderView({ id, setIsOpen, tabActive, onTabChange }: OpportunityHeaderViewProps) {
  const { t } = useTranslation(['opportunities']);
  const { add } = useNotifications();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const navigate = useNavigate();
  const { normalizedOpportunity: opportunity } = useOpportunityDetails(id);

  const location = useLocation({ location: opportunity ? opportunity.location : [] });
  const isEnable = useIsOpportunityEnabled(opportunity);
  const { isPremium } = useCompanyRole();
  const opportunityBelongsToMyCompany = useBelongsToMyCompany(opportunity?.company);
  const canOffer = (opportunity?.isPublished && isPremium) || opportunity?.isPaid;

  const isMineAndPublished = opportunityBelongsToMyCompany && opportunity?.isPublished;

  const teaserId = opportunity?.files.find(({ type }) => type === 'teaser')?.file.id;

  const handleOnClick = canOffer ? () => setIsOpen(true) : () => setIsModalOpen(true);
  const sendDownloadStats = useSendOpportunityStats({ type: 'download_teaser' });

  const sendStatsAndOpen = () => {
    if (!opportunity) return;
    sendDownloadStats(opportunity?.id);
    window.open(`${ASSETS_BASE_URL}/${teaserId}`, '_blank');
  };

  const handleOnClickTeaser = canOffer ? () => sendStatsAndOpen() : () => setIsModalOpen(true);

  if (isBoolean(isEnable) && !isEnable) {
    navigate(Route.Home);
  }

  const canSendForRevision =
    opportunity?.isPending && opportunity?.projects?.filter((project) => project.details?.isDraft).length;
  const sendForRevision = useSendForRevision(opportunity);
  const saveForLater = useCallback(() => {
    add({
      id: new Date().toString(),
      title: 'Project saved successfully',
      description: 'Your project has been saved for later.',
    });
  }, [add]);

  const status =
    opportunity?.deadline.category === 'overdue'
      ? {
          color: 'disabled' as ChipColor,
          name: t('closed'),
        }
      : opportunity?.status;

  if (!opportunity) return null;

  return (
    <AnimationShell type="opacity" className={cn('shrink-0 space-y-4')}>
      <div className="inline-flex w-full items-center justify-between gap-4">
        <div className="inline-flex items-center justify-center gap-2">
          <Icons size="xl" icons={opportunity.technologies.icons.states} />
        </div>
        <div className="inline-flex items-center justify-start gap-2">
          {!opportunityBelongsToMyCompany && (
            <Button size="sm" color="secondary" icon="check" onClick={handleOnClick}>
              {t('Express Interest')}{' '}
            </Button>
          )}
          {!opportunityBelongsToMyCompany && teaserId && (
            <Button size="sm" color="neutral" icon="download" onClick={handleOnClickTeaser} className="px-6 py-2">
              {t('Download Teaser')}{' '}
            </Button>
          )}
          <AnimationShell type="slide-left" className="flex gap-2">
            {opportunityBelongsToMyCompany && canSendForRevision ? (
              <>
                <Button size="md" color="secondary" onClick={sendForRevision}>
                  {t('Send for revision')}
                </Button>
                <Button size="md" color="neutral" onClick={saveForLater}>
                  {t('Save for later')}
                </Button>
              </>
            ) : (
              !opportunity.isPaid && <Chip color={status?.color as ChipColor}> {status?.name} </Chip>
            )}
          </AnimationShell>
        </div>
      </div>

      <AnimationShell type="slide-left">
        <Typography as="h1" size="heading-1" color="primary" weight="semibold">
          {opportunity.name}
        </Typography>
        <Typography as="p" size="body-2" color="primary" weight="normal">
          {location}
        </Typography>
      </AnimationShell>

      {isMineAndPublished ? (
        <OpportunityStats opportunity={opportunity} />
      ) : (
        <OpportunityCards opportunity={opportunity} />
      )}

      <OpportunityHeaderTabs opportunity={opportunity} activeTabIndex={tabActive} onTabChange={onTabChange} />

      <GoUpgradeModal open={isModalOpen} setOpen={setIsModalOpen}></GoUpgradeModal>
    </AnimationShell>
  );
}
