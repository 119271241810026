import { useCallback } from 'react';
import ReactGa from 'react-ga4';
import { useTranslation } from 'react-i18next';

import { Button, Drawer } from '@/components';
import { SendInterestForm, SendInterestFormFields } from './form';
import { useDealDetails } from '@/features/deals';
import { useOnCreateInterest } from '../../hooks';

export type CreateInterestDrawerProps = {
  readonly id: string;
  readonly isOpen: boolean;
  readonly setIsOpen: (state: boolean) => void;
};

export function SendInterestDrawer({ id, isOpen, setIsOpen }: CreateInterestDrawerProps) {
  const deal = useDealDetails(id);
  const { t } = useTranslation(['deals']);

  const onCloseSuccessSubmit = useCallback(() => {
    setIsOpen(false);
    if (!deal) return;

    ReactGa.event({
      category: 'form_submitted',
      action: `New Interest to Deal`,
      label: `Interest on "${deal.title}" deal`,
    });
  }, [setIsOpen, deal]);

  const onClose = useCallback(() => {
    setIsOpen(false);
    if (!deal) return;

    ReactGa.event({
      category: 'form_closed',
      action: `Interest creation proccess cancelled`,
      label: `User has abandoned the interest creation process on "${deal.title}" deal`,
    });
  }, [setIsOpen, deal]);

  const sendInterest = useOnCreateInterest({ id, onClose: onCloseSuccessSubmit });

  const onSubmit = useCallback(
    (data: SendInterestFormFields) => {
      sendInterest(data);
      onCloseSuccessSubmit();
    },
    [sendInterest, onCloseSuccessSubmit]
  );

  const footer = (
    <>
      <Button size="lg" color="neutral" onClick={onClose}>
        {t('Cancel')}
      </Button>
      <Button type="submit" form="create-interest-form" size="lg" color="primary">
        {t('Send interest')}
      </Button>
    </>
  );

  if (!deal) return null;

  return (
    <Drawer title={t('Send interest')} open={isOpen} onClose={onClose} footer={footer}>
      <SendInterestForm id="create-interest-form" deal={deal} onSubmit={onSubmit} />
    </Drawer>
  );
}
