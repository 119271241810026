import { cn } from '@/utils/styles';
import { TransactionDetails } from '../../types';
import { Icon, Typography } from '@/components';
import { IconName } from '@/components/icons/types';

export function TransactionListItem({ transaction }: { transaction: TransactionDetails }) {
  const rowClassName = 'p-1 border-b border-neutral-50 border-b-2';
  return (
    <div className="flex w-full gap-1 py-1">
      <div className={cn('w-1/12', rowClassName)}>
        <Typography size="body-3" color="primary" weight="normal">
          {transaction.dateLabel}
        </Typography>
      </div>
      <div className={cn('w-3/12', rowClassName)}>
        <Typography size="body-3" color="primary" weight="normal">
          {transaction.buyer}
        </Typography>
      </div>
      <div className={cn('w-3/12', rowClassName)}>
        <Typography size="body-3" color="primary" weight="normal">
          {transaction.seller}
        </Typography>
      </div>
      <div className={cn('w-2/12', rowClassName, 'flex gap-1')}>
        {transaction.technologies.map((technology) => (
          <Icon name={technology as IconName} className="h-5 w-5" key={`${technology}-${transaction.id}`} />
        ))}
      </div>
      <div className={cn('w-1/12', rowClassName)}>
        <Typography size="body-3" color="primary" weight="normal">
          {transaction.capacityLabel}
        </Typography>
      </div>
      <div className={cn('w-1/12', rowClassName)}>
        <Typography size="body-3" color="primary" weight="normal">
          {transaction.city}
        </Typography>
      </div>
      <div className={cn('w-1/12', rowClassName)}>
        <Typography size="body-3" color="primary" weight="normal">
          {transaction.country}
        </Typography>
      </div>
    </div>
  );
}
