import { ChipColor } from '@/components/chips/types';
import { projectItemListTransformer } from '@/features/projects/helpers';
import { ProjectItemList } from '@/features/projects/types';

import {
  getIconNamesFromOpportunity,
  getColorFromOpportunityStatus,
  getIconStatesFromOpportunity,
  getUrgencyFromDeadline,
  getLocationFromOpportunityProjects,
  getTechnologyTypesFromOpportunityProjects,
  getDevelopmentStatusFromOpportunityProjects,
  getCapacityFromOpportunityProjects,
} from '../helpers';
import { Opportunity, OpportunityItemList } from '../types';

export function opportunityItemListTransformer(opportunity: Opportunity): OpportunityItemList {
  const opportunityProjects = opportunity.projects.map((project) => ({
    name: project ? project.name ?? project.project?.title : '',
    details: project ? projectItemListTransformer(project.project) : ({} as ProjectItemList),
  }));
  const projects = opportunityProjects.map((project) => project.details);
  const deadline = new Date(opportunity.deadline);

  return {
    id: opportunity.id,
    name: opportunity.name,
    company: opportunity.company,
    deadline: {
      date: deadline,
      category: getUrgencyFromDeadline(deadline),
    },
    location: getLocationFromOpportunityProjects(projects),
    technologies: {
      types: getTechnologyTypesFromOpportunityProjects(projects),
      icons: {
        name: getIconNamesFromOpportunity(opportunity),
        states: getIconStatesFromOpportunity(opportunity),
      },
      state: {
        list: getDevelopmentStatusFromOpportunityProjects(projects),
      },
      capacity: getCapacityFromOpportunityProjects(projects),
    },
    status: {
      name: opportunity.status === 'published' ? 'for business' : opportunity.status,
      color: getColorFromOpportunityStatus(opportunity.status) as ChipColor,
    },
    projects: opportunityProjects,
    isPublished: opportunity.status === 'published',
    isPaid: opportunity.type.toLowerCase() === 'paid',
    hasPV: checkOpportunityHasPv(projects),
    offers: opportunity.offers.map((offer) => offer.id),
    companiesWhoOffered: opportunity.offers.map((offer) => offer.company?.id),
  };
}

export function checkOpportunityHasPv(projects: ProjectItemList[]) {
  return projects.some((project) => project.technologies.types.includes('solar'));
}
