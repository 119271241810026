import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';

import { Icon, Typography } from '@/components';
import { cn } from '@/utils/styles';

import { IconName } from '../icons/types';

export type MenuItemProps = {
  readonly to: string;
  readonly label: string;
  readonly icon: IconName;
  readonly active: boolean;
  readonly collapsed: boolean;
  readonly index?: number;
};

export function MenuItem({ to, icon, label, active, collapsed, index = 0 }: MenuItemProps) {
  return (
    <li
      className={cn(
        'h-8 cursor-pointer rounded-lg',
        collapsed ? 'w-12 px-0' : 'w-full px-3.5',
        'group transition hover:bg-white/5',
        active ? 'bg-white/5' : 'bg-transparent'
      )}
    >
      <Link
        to={to}
        className={cn(
          'inline-flex h-full items-center gap-x-2.5',
          collapsed ? 'h-12 w-12 justify-center' : 'w-full justify-start'
        )}
      >
        <Icon
          name={icon}
          className={cn(
            'relative h-5 w-5 shrink-0',
            active ? 'text-secondary-400' : 'text-neutral-500 group-hover:text-secondary-400',
            collapsed && 'left-1'
          )}
        />

        <motion.span
          initial={{ opacity: 1, width: 'auto' }}
          animate={{ opacity: collapsed ? 0 : 1, width: collapsed ? 0 : 'auto' }}
          transition={{ duration: 0.1 * index }}
          className={cn('tracking-wider', active ? 'text-white' : 'text-neutral-500 group-hover:text-white')}
        >
          <Typography as="span" size="body-2" color="inherit" weight="semibold">
            {label}
          </Typography>
        </motion.span>
      </Link>
    </li>
  );
}
