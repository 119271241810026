import { Deal, DealDetails } from '../types';

export function dealDetailsTransformer(deal?: Deal): DealDetails | null {
  if (!deal) return null;

  return {
    id: deal.id,
    title: deal.title,
    country: deal.country,
    city: deal.city,
    technologies: deal.technologies,
    category: deal.category,
    deadline: new Date(deal.publish_date),
    description: deal.description,
    companyDescription: deal.company_description,
    attachments: deal.attachments.map((attachment) => attachment.directus_files_id),
    teaserId: deal.attachments.find((attachment) => attachment.type === 'teaser')?.directus_files_id.id || null,
  };
}
