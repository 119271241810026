import { useCompanyRole } from '@/features/company/hooks';
import { TransactionDetails } from '../../types';
import { TransactionListItem } from './transaction-list-item';
import { Overlay } from '@/components';

export function TransactionListItems({ transactions }: { transactions: TransactionDetails[] }) {
  const { isPremium } = useCompanyRole();
  if (isPremium) {
    return transactions?.map((transaction) => <TransactionListItem transaction={transaction} key={transaction.id} />);
  } else {
    return (
      <>
        {transactions
          ?.slice(0, 5)
          .map((transaction) => <TransactionListItem transaction={transaction} key={transaction.id} />)}
        <Overlay />
      </>
    );
  }
}
