import { useTranslation } from 'react-i18next';

import { Button } from '@/components';
import { useOnClickViewOpportunities } from '@/features/offers/hooks';

export function MyOffersHeader() {
  const { t } = useTranslation(['offers']);
  const handleOnClick = useOnClickViewOpportunities();

  return (
    <div className="flex justify-end">
      <Button size="md" color="primary" icon="plus" onClick={handleOnClick}>
        {t('View opportunities')}
      </Button>
    </div>
  );
}
